import "./style.scss"

export default function Checkbox({ name, value, onChange, uniqueKey }) {
  return (
    <div className="form-checkbox">
      <input className="checkbox" type="checkbox" checked={value} onChange={onChange} id={uniqueKey} />
      <label htmlFor={uniqueKey}>{name}</label>
    </div>
  )
}
