import { useState } from 'react'
import { Listbox } from '@headlessui/react'

import "./style.scss"

export default function Multiselect({ data, label, selectedCategories = [], setSelectedCategories = () => {} }) {
  return (
    <div className="multiSelect">
      <Listbox value={selectedCategories} onChange={setSelectedCategories} multiple>
        {label ? <Listbox.Label>{label}:</Listbox.Label> : null}
        <Listbox.Button className="listboxBtn">
          {selectedCategories?.map((category) => category.name).join(', ') || "Izaberi kategorije"}
          <img src="/icons/select-icon.svg" alt="" width="16" height="17" className="selectIcon" />
        </Listbox.Button>
        <Listbox.Options className="listboxOptions">
          {data?.map((d) => (
            <Listbox.Option key={d.id} value={d}>
              {d.name}
              {selectedCategories?.find(c => c.id === d.id) ? <img src="/icons/correct.svg" alt="" width="15" height="15" /> : null}
            </Listbox.Option>
          ))}
        </Listbox.Options>
        
      </Listbox>
    </div>
  )
}
