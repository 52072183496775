import { Routes, Route } from "react-router-dom";

import Products from "pages/products/index.js"
import AddProduct from "pages/add-product/index.js"
import EditProduct from "pages/edit-product/index.js"
import Profile from "pages/profile/index.js"
import Categories from "pages/categories/index.js"
import Settings from "pages/settings/index.js"
import Login from "pages/login/index.js"
import Projects from "pages/projects/index.js"
import AddProject from "pages/add-project/index.js"
import EditProject from "pages/edit-project/index.js"

import withAuth from "hoc/withAuth.js"

export default function AllRoutes() {
  const ProtectedProducts = withAuth(Products)
  const ProtectedSettings = withAuth(Settings)
  const ProtectedProfile = withAuth(Profile)
  const ProtectedCategories = withAuth(Categories)
  const ProtectedAddProduct = withAuth(AddProduct)
  const ProtectedEditProduct = withAuth(EditProduct)
  const ProtectedProjects = withAuth(Projects)
  const ProtectedAddProject = withAuth(AddProject)
  const ProtectedEditProject = withAuth(EditProject)

  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />

        <Route exact path="/podesavanja" element={<ProtectedSettings />} />
        <Route exact path="/kategorije" element={<ProtectedCategories />} />
        <Route exact path="/profil" element={<ProtectedProfile />} />
        <Route exact path="/dodaj-proizvod" element={<ProtectedAddProduct />} />
        <Route exact path="/izmijeni-proizvod/:id" element={<ProtectedEditProduct />} />
        <Route exact path="/izmijeni-projekat/:id" element={<ProtectedEditProject />} />
        <Route exact path="/dodaj-projekat" element={<ProtectedAddProject />} />
        <Route exact path="/projekti" element={<ProtectedProjects />} />
        <Route path="*" element={<ProtectedProducts />} />
    </Routes>
  )
}
