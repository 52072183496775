import { gql } from "@apollo/client"

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProductsById(id: $id)
  }
`

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($payload: CreateProductsInput!) {
    createProducts(payload: $payload) {
      name
      description
      featuredImage
      gallery
      price
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($payload: UpdateProductsInput! $id: ID!) {
    updateProductsById(payload: $payload, id: $id) {
      name
      description
      featuredImage
      gallery
      price
    }
  }
`
