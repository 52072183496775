import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation Login($payload: LoginPayload!) {
    login(payload: $payload) {
      token
      user {
        id
        email
        isAdmin
      }
    }
  }
`

export const UPDATE_ME = gql`
  mutation UpdateMe($payload: UpdateMeInput!) {
    updateMe(payload: $payload) {
      email
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String! $newPassword: String!) {
    changePassword(oldPassword: $oldPassword newPassword: $newPassword)
  }
`
