import { useState } from "react";
import { Link } from "react-router-dom";

import "./style.scss";

export default function Table({
  data = [],
  headers = [],
  remove,
  edit,
  perPage = 10,
}) {
  const [page, setPage] = useState(1);

  const incrementPage = () => {
    const maxPages = Math.ceil(data.length / perPage);

    if (page < maxPages) {
      setPage((prevState) => prevState + 1);
    }
  };

  const decrementPage = () => {
    if (page > 1) {
      setPage((prevState) => prevState - 1);
    }
  };

  return (
    <div className="tableContainer">
      <table>
        <thead>
          <tr>
            {headers.map((header, key) => (
              <th key={key}>{header.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice((page - 1) * perPage, page * perPage).map((row, key) => (
            <tr key={key}>
              {headers.map((header, key2) => (
                <td key={key2}>{row[header.key]}</td>
              ))}
              {remove || edit ? (
                <td className="tableAction">
                  {remove ? (
                    <span onClick={() => remove(row.id)}>
                      <img src="/icons/trash.svg" alt="X" />
                    </span>
                  ) : null}
                  {edit ? (
                    <Link to={edit(row.id)}>
                      <img src="/icons/edit.svg" alt="" />
                    </Link>
                  ) : null}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <div onClick={decrementPage}>Prethodna stranica</div>
        <div onClick={incrementPage}>Sljedeca stranica</div>
      </div>
    </div>
  );
}
