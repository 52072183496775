import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client"
import MDEditor from '@uiw/react-md-editor';

import { BACKEND_HOST } from "constants/index.js"

import { GET_PROJECT } from "api/queries/projects.js"
import { GET_CATEGORIES } from "api/queries/categories.js"
import { UPDATE_PROJECT } from "api/mutations/projects.js"

import notify from "utils/notify.js"

import Checkbox from "components/checkbox/index.js"
import MultiSelect from "components/multiselect/index.js"
import Input from "components/input/index.js"
import Button from "components/button/index.js"
import Upload from "components/upload/index.js"

import "./style.scss"

function Settings() {
  const [payload, setPayload] = useState({})
  const { id } = useParams();

  const { data: { getProjects: project } = {} } = useQuery(GET_PROJECT, {
    variables: {
      id
    }
  })

  const { data: { getAllCategories: categories } = {} } = useQuery(GET_CATEGORIES)

  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: d => {
      notify("Projekat uspjesno izmijenjen", "success")

      setTimeout(() => {
        window.location.href = "/projekti"
      }, 800)
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  useEffect(() => {
    if(project) {
      setPayload({
        ...project,
        gallery: project.gallery?.join(",")
      })
    }
  }, [project])

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const uploadFile = async (e, type) => {
    const formData = new FormData()    

    for(const file of e.target.files) {
      formData.append("files[]", file)
    }

    formData.set('customHash', payload.customHash)

    try {
      const response = await fetch(`${BACKEND_HOST}/api/multi-upload`, {
        method: "POST",
        body: formData,
      })

      const data = await response.json()

      const result = data?.uploadedFiles?.join(",")

      if(result) {
        if(type === "gallery" && payload.gallery) {
          setPayload(prevState => ({
            ...prevState,
            [type]: `${prevState.gallery},${result}`
          }))
        } else {
          setPayload(prevState => ({
            ...prevState,
            [type]: result
          }))
        }

        notify("Slika uspjesno otpremljena", "success")
      }
    } catch(e) {
      notify("Doslo je do greske prilikom cuvanja slike", "error")
    }
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    updateProject({
      variables: {
        payload: {
          name: payload.name,
          description: payload.description,
          featuredImage: payload.featuredImage,
          featured: payload.featured,
          gallery: payload.gallery,
//          categories: payload.categories?.map(p => p.id),
 //         price: parseFloat(payload.price)
        },
        id
      }
    })
  }

  const removeImageFromGallery = link => {
    const filteredGallery = payload.gallery?.split(",").filter(l => l !== link).join(",")

    setPayload(prevState => ({
      ...prevState,
      gallery: filteredGallery
    }))
  }

  
  const categoriesChanged = d => {
    const duplicates = d?.filter((item, index) => {
      return d?.findIndex(it => it.id === item.id) !== index
    }).map(x => x.id)

    setPayload(prevState => ({
      ...prevState,
      categories: d.filter(x => {
        if(!duplicates.includes(x.id)) {
          return true
        }

        return false
      })
    }))
  }

  const checkboxChanged = e => {
    setPayload(prevState => ({
      ...prevState,
      featured: e?.target?.checked
    }))
  }

  return (
    <>
      <div className="editProjectContainer">
        <form onSubmit={submitForm}>
          <div className="loginInputs">
            <Input label="Naziv projekta *" type="text" value={payload.name} onChange={e => changePayload(e?.target?.value, "name")} />
            <div>
              <p className="markdownOpis">Opis</p>
              <MDEditor value={payload.description} onChange={val => changePayload(val, "description")} />
            </div>
            {/*
            <MultiSelect selectedCategories={payload?.categories} setSelectedCategories={categoriesChanged} data={categories} label="Kategorije" />
            */}
            <Checkbox name="Istakni prozivod" value={payload.featured} onChange={checkboxChanged} uniqueKey={"featured-checkbox"} />
            <Upload label="Istaknuta fotografija *" keyUpload="1" value={undefined} onChange={e => uploadFile(e, "featuredImage")} />
            {payload.featuredImage ?
              <div className="showcaseImages">
                <div>
                  <img src="/icons/full-trash.svg" alt="" className="deleteImage" onClick={() => setPayload(prevState => ({ ...prevState, featuredImage: "" }))} />
                  <div className="imageContainer">
                    <img src={payload.featuredImage} alt="" />
                  </div>
                </div>
              </div>
              : null
            }
            <Upload label="Galerija" keyUpload="2" multiple value={undefined} onChange={e => uploadFile(e, "gallery")} />
            {payload.gallery ?
              <div className="showcaseImages">
                {payload.gallery?.split(",").map((photo, id) => (
                  <div>
                    <img src="/icons/full-trash.svg" alt="" className="deleteImage" onClick={() => removeImageFromGallery(photo)} />
                    <div className="imageContainer">
                      <img src={photo} alt="" key={id} />
                    </div>
                  </div>
                ))}
              </div>
              : null
            }

            <Button type="submit" name="Sacuvaj" loading={loading} />
          </div>
        </form>
      </div>
    </>
  )
}

export default Settings
