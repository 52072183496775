import { gql } from "@apollo/client"

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($payload: UpdateSettingsInput!) {
    updateSettings(payload: $payload) {
      contactPhone
      workingHours
      contactEmail
    }
  }
`
