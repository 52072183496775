import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import MDEditor from '@uiw/react-md-editor';

import { nanoid } from "nanoid"

import { BACKEND_HOST } from "constants/index.js"

import { CREATE_PRODUCT } from "api/mutations/products.js"
import { GET_CATEGORIES } from "api/queries/categories.js"

import notify from "utils/notify.js"

import Checkbox from "components/checkbox/index.js"
import MultiSelect from "components/multiselect/index.js"
import Input from "components/input/index.js"
import Button from "components/button/index.js"
import Upload from "components/upload/index.js"

import "./style.scss"

function Settings() {
  const [payload, setPayload] = useState({})
  const [realLoading, setUploading] = useState({
    featuredImage: false,
    gallery: false,
  })

  const { data: { getAllCategories: categories } = {} } = useQuery(GET_CATEGORIES)

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: d => {
      notify("Proizvod uspjesno dodat", "success")

      setTimeout(() => {
        window.location.href = "/"
      }, 800)
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  useEffect(() => {
    setPayload(prevState => ({
      ...prevState,
      customHash: `${nanoid()}${Date.now()}`
    }))
  }, [])

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }

  const uploadFile = async (e, type) => {
    const formData = new FormData()    

    setUploading(prevState => ({
      ...prevState,
      [type]: true
    }))

    for(const file of e.target.files) {
      formData.append("files[]", file)
    }

    formData.set('customHash', payload.customHash)

    try {
      const response = await fetch(`${BACKEND_HOST}/api/multi-upload`, {
        method: "POST",
        body: formData,
      })

      const data = await response.json()

      const result = data?.uploadedFiles?.join(",")

      if(result) {
        setPayload(prevState => ({
          ...prevState,
          [type]: result
        }))

        notify("Slika uspjesno otpremljena", "success")
      }
    } catch(e) {
      notify("Doslo je do greske prilikom cuvanja slike", "error")
    }

    setUploading(prevState => ({
      ...prevState,
      [type]: false
    }))
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    createProduct({
      variables: {
        payload: {
          ...payload,
          categories: payload.categories?.map(x => x.id),
          price: 0.01 || parseFloat(payload.price)
        }
      }
    })
  }

  const removeImageFromGallery = link => {
    const filteredGallery = payload.gallery?.split(",").filter(l => l !== link).join(",")

    setPayload(prevState => ({
      ...prevState,
      gallery: filteredGallery
    }))
  }

  const categoriesChanged = d => {
    const duplicates = d?.filter((item, index) => {
      return d?.findIndex(it => it.id === item.id) !== index
    }).map(x => x.id)

    setPayload(prevState => ({
      ...prevState,
      categories: d.filter(x => {
        if(!duplicates.includes(x.id)) {
          return true
        }

        return false
      })
    }))
  }

  const checkboxChanged = e => {
    setPayload(prevState => ({
      ...prevState,
      featured: e?.target?.checked
    }))
  }

  return (
    <>
      <div className="addProductContainer">
        <form onSubmit={submitForm}>
          <div className="loginInputs">
            <Input label="Naziv proizvoda *" type="text" value={payload.name} onChange={e => changePayload(e?.target?.value, "name")} />
            <div>
              <p className="markdownOpis">Opis</p>
              <MDEditor value={payload.description} onChange={val => changePayload(val, "description")} />
            </div>

            {/*
            <Input label="Opis" type="text" value={payload.description} onChange={e => changePayload(e?.target?.value, "description")} />
            <Input label="Cijena *" type="number" step=".01" min="0" value={payload.price} onChange={e => changePayload(e?.target?.value, "price")} />
            */}
            <MultiSelect selectedCategories={payload?.categories} setSelectedCategories={categoriesChanged} data={categories} label="Kategorije" />
            <Checkbox name="Istakni proizvod" value={payload.featured} onChange={checkboxChanged} uniqueKey={"featured-checkbox"} />
            <Upload label="Istaknuta fotografija *" keyUpload="1" value={undefined} onChange={e => uploadFile(e, "featuredImage")} />
            {payload.featuredImage ?
              <div className="showcaseImages">
                <div>
                  <img src="/icons/full-trash.svg" alt="" className="deleteImage" onClick={() => setPayload(prevState => ({ ...prevState, featuredImage: "" }))} />
                  <div className="imageContainer">
                    <img src={`${BACKEND_HOST}/${payload.featuredImage}`} alt="" />
                  </div>
                </div>
              </div>
              : null
            }
            <Upload label="Galerija" keyUpload="2" multiple value={undefined} onChange={e => uploadFile(e, "gallery")} />
            {payload.gallery ?
              <div className="showcaseImages">
                {payload.gallery?.split(",").map((photo, id) => (
                  <div>
                    <img src="/icons/full-trash.svg" alt="" className="deleteImage" onClick={() => removeImageFromGallery(photo)} />
                    <div className="imageContainer">
                      <img src={`${BACKEND_HOST}/${photo}`} alt="" key={id} />
                    </div>
                  </div>
                ))}
              </div>
              : null
            }

            <Button type="submit" name="Sacuvaj" loading={loading || realLoading.gallery || realLoading.featuredImage} />
          </div>
        </form>
      </div>
    </>
  )
}

export default Settings
