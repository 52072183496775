import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Link } from "react-router-dom"

import { GET_ALL_PRODUCTS } from "api/queries/products.js"
import { DELETE_PRODUCT } from "api/mutations/products.js"

import notify from "utils/notify.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"
import Table from "components/table/index.js"
import Modal from "components/modal/index.js"

import "./style.scss"

function Products() {
  const [showModal, setShowModal] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState()
  const [payload, setPayload] = useState({})

  const { data: { getAllProducts: products } = {}, refetch } = useQuery(GET_ALL_PRODUCTS)

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: d => {
      notify("Proizvod uspjesno izbrisan", "success")

      refetch()

      setShowModal(false)
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
    
  const removeProduct = (id) => {
    setShowModal(true)

    setDeleteProductId(id)
  }

  const realDeleteProduct = () => {
    deleteProduct({
      variables: {
        id: deleteProductId
      }
    })
  }

  return (
    <>
      {showModal ?
          <Modal title={"Da li ste sigurni da zelite izbrisati proizvod?"} onConfirm={realDeleteProduct} onClose={() => setShowModal(false)} />
        : null
      }
      <div className="productsContainer">
        <Link to="/dodaj-proizvod">
          <Button name="Dodaj novi proizvod" className="addProductBtn" />
        </Link>

        <div className="allProducts">
          <h3>Trenutni proizvodi</h3>

          <Table data={products} headers={[{ label: "ID", key: "id" }, { label: "Ime proizvoda", key: "name" }, { label: "Opis", key: "description" }, { label: "Cijena", key: "price" }]} remove={removeProduct} edit={(id) => `/izmijeni-proizvod/${id}`} />
        </div>

      </div>
    </>
  )
}

export default Products
