import { useContext } from "react"
import { useLocation, Link } from 'react-router-dom';

import { GlobalContext } from "context/global.js"

import { AUTH_TOKEN } from "constants/index.js"

import "./style.scss"

export const links = [
  { icon: <img src="/icons/dashboard.svg" alt="" className="icon" />, exactLink: true, name: "Proizvodi", to: "/" },
  { excludeFromSidebar: true, exactLink: true, name: "Dodaj Proizvod", to: "/dodaj-proizvod" },
  { excludeFromSidebar: true, exactLink: false, name: "Izmijeni Proizvod", to: "/izmijeni-proizvod" },
  { icon: <img src="/icons/dashboard.svg" alt="" className="icon" />, exactLink: true, name: "Projekti", to: "/projekti" },
  { excludeFromSidebar: true, exactLink: true, name: "Dodaj Projekat", to: "/dodaj-projekat" },
  { excludeFromSidebar: true, exactLink: false, name: "Izmijeni Projekat", to: "/izmijeni-projekat" },
  { icon: <img src="/icons/categories.svg" alt="" className="icon" />, exactLink: true, name: "Kategorije", to: "/kategorije" },
  { icon: <img src="/icons/settings.svg" alt="" className="icon" />, exactLink: true, name: "Podesavanja", to: "/podesavanja" },
  { icon: <img src="/icons/user.svg" alt="" className="icon" />, exactLink: true, name: "Profil", to: "/profil" },
]

export default function Sidebar({ children }) {
  const { state, setState } = useContext(GlobalContext)

  const location = useLocation()

  const closeSidebar = (e) => {
    setState(prevState => ({
      ...prevState,
      sidebarOpened: false
    }))
  }

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN)

    window.location.reload()
  }

  return (
    <div className={`sidebarContainer ${state.sidebarOpened ? "" : "hide"}`} onClick={closeSidebar}>
      <div className="sidebar" onClick={e => e.stopPropagation()}>
        <div className="closeSidebar" onClick={closeSidebar}>
          <img src="/icons/close.svg" alt="" />
        </div>

        <div className="navigationLinks">
          {links.map((link, idx) => (link.excludeFromSidebar ? null :
            <Link to={link.to} key={idx}>
              <div className={`link ${location.pathname === link.to ? "active" : ""}`}>
                {link.icon}
                {link.name}
              </div>
            </Link>
          ))}
        </div>

        <div className="logout" onClick={logout}>
          <img src="/icons/logout.svg" alt="" className="icon" />
          <span>Odjavi se</span>
        </div>
      </div>
    </div>
  )
}
