import { gql } from "@apollo/client"

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProjectsById(id: $id)
  }
`

export const CREATE_PROJECT = gql`
  mutation CreateProject($payload: CreateProjectsInput!) {
    createProjects(payload: $payload) {
      name
      description
      featuredImage
      gallery
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($payload: UpdateProjectsInput! $id: ID!) {
    updateProjectsById(payload: $payload, id: $id) {
      name
      description
      featuredImage
      gallery
    }
  }
`
