import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { GET_CATEGORIES } from "api/queries/categories.js"
import { DELETE_CATEGORY, CREATE_CATEGORY } from "api/mutations/categories.js"

import notify from "utils/notify.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"
import Table from "components/table/index.js"
import Modal from "components/modal/index.js"

import "./style.scss"

function Categories() {
  const [showModal, setShowModal] = useState(false)
  const [deleteCategoryId, setDeleteCategoryId] = useState()
  const [payload, setPayload] = useState({})

  const { data: { getAllCategories: categories } = {}, refetch } = useQuery(GET_CATEGORIES)

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: d => {
      notify("Kategorija uspjesno izbrisana", "success")

      refetch()

      setShowModal(false)
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  const [createCategory, { loading }] = useMutation(CREATE_CATEGORY, {
    onCompleted: d => {
      notify("Kategorija uspjesno dodata", "success")

      setPayload({ name: "", description: "" })
      refetch()
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    createCategory({
      variables: {
        payload: {
          name: payload.name,
          description: payload.description
        }
      }
    })
  }

  const removeCategory = (id) => {
    setShowModal(true)

    setDeleteCategoryId(id)
  }

  const realDeleteCategory = () => {
    deleteCategory({
      variables: {
        id: deleteCategoryId
      }
    })
  }

  return (
    <>
      {showModal ?
          <Modal title={"Da li ste sigurni da zelite izbrisati kategoriju?"} onConfirm={realDeleteCategory} onClose={() => setShowModal(false)} />
        : null
      }
      <div className="categoriesContainer">
        <form onSubmit={submitForm}>
          <h3>Dodaj novu kategoriju</h3>
          <div className="loginInputs">
            <Input label="Ime kategorije" type="text" value={payload.name} onChange={e => changePayload(e?.target?.value, "name")} />
            <Input label="Opis" type="text" value={payload.description} onChange={e => changePayload(e?.target?.value, "description")} />

            <Button type="submit" name="Dodaj kategoriju" loading={loading} />
          </div>
        </form>

        <div>
          <h3>Trenutne kategorije</h3>

          <Table data={categories} headers={[{ label: "ID", key: "id" }, { label: "Ime kategorije", key: "name" }, { label: "Opis", key: "description" }]} remove={removeCategory} />
        </div>

      </div>
    </>
  )
}

export default Categories
