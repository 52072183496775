import { gql } from "@apollo/client"

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategoriesById(id: $id)
  }
`

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($payload: CreateCategoriesInput!) {
    createCategories(payload: $payload) {
      name
      description
    }
  }
`
