import { useState } from "react"
import { useQuery } from "@apollo/client"

import Navigation from "containers/navigation/index.js"

import { GET_ME } from "api/queries/user.js"

function withAuth(Component) {
  return function() {
    const [state, setState] = useState()

    useQuery(GET_ME, {
      fetchPolicy: "network-only",
      onCompleted: d => {
        if(d?.me?.id) {
          setState(true)

          return;
        }

        window.location.href = "/login"
      },
      onError: e => {
        window.location.href = "/login"
      }
    })

    return state ?
      <Navigation><Component /></Navigation> : null;
  }
}

export default withAuth
