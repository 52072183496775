import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Link } from "react-router-dom"

import { GET_ALL_PROJECTS } from "api/queries/projects.js"
import { DELETE_PROJECT } from "api/mutations/projects.js"

import notify from "utils/notify.js"

import Button from "components/button/index.js"
import Table from "components/table/index.js"
import Modal from "components/modal/index.js"

import "./style.scss"

function Projects() {
  const [showModal, setShowModal] = useState(false)
  const [deleteProjectId, setDeleteProjectId] = useState()

  const { data: { getAllProjects: projects } = {}, refetch } = useQuery(GET_ALL_PROJECTS)

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted: d => {
      notify("Proizvod uspjesno izbrisan", "success")

      refetch()

      setShowModal(false)
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  const removeProject = (id) => {
    setShowModal(true)

    setDeleteProjectId(id)
  }

  const realDeleteProject = () => {
    deleteProject({
      variables: {
        id: deleteProjectId
      }
    })
  }

  return (
    <>
      {showModal ?
          <Modal title={"Da li ste sigurni da zelite izbrisati projekat?"} onConfirm={realDeleteProject} onClose={() => setShowModal(false)} />
        : null
      }
      <div className="projectsContainer">
        <Link to="/dodaj-projekat">
          <Button name="Dodaj novi projekat" className="addProjectBtn" />
        </Link>

        <div className="allProjects">
          <h3>Projekti</h3>

          <Table data={projects} headers={[{ label: "ID", key: "id" }, { label: "Ime projekata", key: "name" }, { label: "Opis", key: "description" }]} remove={removeProject} edit={(id) => `/izmijeni-projekat/${id}`} />
        </div>

      </div>
    </>
  )
}

export default Projects
