import { useState, useContext } from "react"
import { useMutation } from "@apollo/client"

import Input from "components/input/index.js"
import Button from "components/button/index.js"

import notify from "utils/notify.js"

import { UserContext } from "context/user.js"

import { AUTH_TOKEN } from "constants/index.js"

import { LOGIN } from "api/mutations/user.js"

import "./style.scss"

export default function Login() {
  const [payload, setPayload] = useState({})

  const { setUser } = useContext(UserContext)

  const [ loginUser, { loading } ] = useMutation(LOGIN, {
    onCompleted: d => {
      if(d?.login?.token) {
        // Set User
        if(d?.login?.user) {
          setUser(d.login.user)

          localStorage.setItem(AUTH_TOKEN, d.login.token)

          window.location.href = "/"
        }
      } else {
        notify("Error occurred", "error")
      }
    },
    onError: e => {
      notify(e?.message || "Error occurred", "error")
    }
  })

  const changePayload = (value, type) => {
    setPayload(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const submitForm = async (e) => {
    e.preventDefault()

    if(["email", "password"].some(key => !payload[key])) {
      notify("Please fill all required fields.", "error")
    
      return;
    }

    loginUser({
      variables: {
        payload
      }
    })
  }

  return (
    <div className="container">
      <form onSubmit={submitForm}>
        <h3>Admin Login</h3>

        <div className="loginInputs">
          <Input label="Email" type="email" required onChange={e => changePayload(e?.target?.value, "email")} />
          <Input label="Password" type="password" required onChange={e => changePayload(e?.target?.value, "password")} />

          <Button type="submit" name="Login" loading={loading} />
        </div>
      </form>
    </div>
  )
}
