import "./style.scss"

export default function Upload({ label, keyUpload, ...args }) {
  return (
    <div className="uploadContainer">
      <label>{label}</label>
      <label className="upload" htmlFor={`upload-input-${keyUpload}`}>
        <img src="/icons/upload.svg" alt="" className="uploadImg" />
        <p>Dodaj fotografiju</p>
        <input id={`upload-input-${keyUpload}`} type="file" className="hidden" {...args} />
      </label>
    </div>
  )
}
