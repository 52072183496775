import BounceLoader from "react-spinners/BounceLoader"

import "./style.scss"

export default function Button({ name, loading, className, ...args }) {
  return (
    <button {...args} className={`button  ${className || ""}`}>
      <span>
        <BounceLoader color="white" loading={!!loading} size={20} className="btnLoader" />
        {name}
      </span>
    </button>
  )
}
