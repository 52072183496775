import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { GET_ME } from "api/queries/user.js"
import { UPDATE_ME, CHANGE_PASSWORD } from "api/mutations/user.js"

import notify from "utils/notify.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"

import "./style.scss"

function Profile() {
  const [payload, setPayload] = useState({})

  const { data: { me } = {} } = useQuery(GET_ME)

  const [updateMe, { loading }] = useMutation(UPDATE_ME, {
    onCompleted: d => {
      notify("Uspjesno sacuvano", "success")
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  const [updatePassword, { loading: passwordLoading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: d => {
      notify("Uspjesno sacuvano", "success")
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  useEffect(() => {
    if(me) {
      setPayload(me)
    }
  }, [me])

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    updateMe({
      variables: {
        payload: {
          email: payload.email
        }
      }
    })
  }

  const changePW = async (e) => {
    e.preventDefault()

    if(!payload.oldPassword) {
      notify("Unesite staru sifru", "error")
      return;
    }

    if(!payload.newPassword) {
      notify("Unesite novu sifru", "error")
      return;
    }

    updatePassword({
      variables: {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      }
    })
  }

  return (
    <>
      <div className="profileContainer">
        <form onSubmit={submitForm}>
          <h3>Osnovni podaci</h3>
          <div className="loginInputs">
            <Input label="Email" type="email" value={payload.email} onChange={e => changePayload(e?.target?.value, "email")} />

            <Button type="submit" name="Sacuvaj" loading={loading} />
          </div>
        </form>

        <form onSubmit={changePW}>
          <h3>Sigurnost</h3>
          <div className="loginInputs">
            <Input label="Stara sifra" type="password" value={payload.oldPassword} onChange={e => changePayload(e?.target?.value, "oldPassword")} />
            <Input label="Nova sifra" type="password" value={payload.newPassword} onChange={e => changePayload(e?.target?.value, "newPassword")} />

            <Button type="submit" name="Promijeni sifru" loading={passwordLoading} />
          </div>
        </form>
      </div>
    </>
  )
}

export default Profile
