import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { GET_SETTINGS } from "api/queries/settings.js"
import { UPDATE_SETTINGS } from "api/mutations/settings.js"

import notify from "utils/notify.js"

import Input from "components/input/index.js"
import Button from "components/button/index.js"

import "./style.scss"

function Settings() {
  const [payload, setPayload] = useState({})

  const { data: { getPublicSettings: settings } = {} } = useQuery(GET_SETTINGS)

  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    onCompleted: d => {
      notify("Uspjesno sacuvano", "success")
    },
    onError: e => {
      notify("Doslo je do greske", "error")
    }
  })

  useEffect(() => {
    if(settings) {
      setPayload(settings)
    }
  }, [settings])

  const changePayload = (value, key) => {
    setPayload(prevState => ({
      ...prevState,
      [key]: value
    }))
  }
    
  const submitForm = async (e) => {
    e.preventDefault()

    updateSettings({
      variables: {
        payload: {
          workingHours: payload.workingHours,
          contactPhone: payload.contactPhone,
          contactEmail: payload.contactEmail,
        }
      }
    })
  }

  return (
    <>
      <div className="settingsContainer">
        <form onSubmit={submitForm}>
          <div className="loginInputs">
            <Input label="Radno vrijeme" type="text" value={payload.workingHours} onChange={e => changePayload(e?.target?.value, "workingHours")} />
            <Input label="Kontakt Telefon" type="text" value={payload.contactPhone} onChange={e => changePayload(e?.target?.value, "contactPhone")} />
            <Input label="Kontakt Email" type="text" value={payload.contactEmail} onChange={e => changePayload(e?.target?.value, "contactEmail")} />

            <Button type="submit" name="Sacuvaj" loading={loading} />
          </div>
        </form>
      </div>
    </>
  )
}

export default Settings
